<template>
  <div id="graduate-class-group" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(add)">
              <SdSelect
                rules="required"
                label="Class Group"
                v-model="classGroupId"
              >
                <option
                  v-for="classGroup in classGroups"
                  :key="classGroup.id"
                  :value="classGroup.id"
                >
                  {{ classGroup.name }}
                </option>
              </SdSelect>

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CLASS_GROUPS from '../../../graphql/class_group/ClassGroups.gql'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'GraduateClassGroup',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduate Class Group',
      skipQuery: true,
      classGroups: [],
      graduateClassGroup: {
        classGroup: {
          id: null,
          name: null,
        },
      },
      classGroupId: null,
    }
  },
  apollo: {
    classGroups: {
      query: CLASS_GROUPS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    graduateClassGroup: {
      query: gql`
        query graduateClassGroupQuery($id: ID!) {
          graduateClassGroup(id: $id) {
            id
            classGroup {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    add() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createGraduateClassGroupQuery($classGroupId: Int!) {
                createGraduateClassGroup(
                  input: { classGroupId: $classGroupId }
                ) {
                  graduateClassGroup {
                    id
                    classGroupId
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              classGroupId: parseInt(this.classGroupId),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createGraduateClassGroup.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/graduate_class_groups`
              )
            })
          })
          .catch((error) => {
            console.error(error.message)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateGraduateClassGroupQuery(
                $id: Int!
                $classGroupId: Int!
              ) {
                updateGraduateClassGroup(
                  input: { id: $id, classGroupId: $classGroupId }
                ) {
                  graduateClassGroup {
                    id
                    classGroupId
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.$route.params.id),
              classGroupId: parseInt(this.classGroupId),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateGraduateClassGroup.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/graduate_class_groups`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Graduate Class Groups',
        route: `/school/${this.schoolId}/graduate_class_groups`,
      },
    ])
  },
}
</script>

<style lang="scss" scoped></style>
